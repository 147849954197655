import { ColorInfo, Flag, PriceInfo } from '~/constants/types/algolia';
import { NorceUserTypes } from '~/constants/norceCodes';

type InfoType = {name: string, value: string};

export type CartApiPayload = {
  items: {
    lineNo?: number,
    partNo: string,
    quantity: number,
    info?: {
      value: string;
      code: string|boolean;
    }[],
    isCrossSell?: boolean,
    priceListLocked?: boolean,
  }[]
}

export type CartApiItem = {
  appliedPromotions: AppliedPromotion[],
  brand: Brand,
  categories: Category[],
  flags: Flag[],
  image: string,
  infoTypes: InfoType[],
  lineNo: number,
  name: string,
  onHandValue: number,
  parentLineNo: null,
  partNo: string,
  priceInfo: PriceInfo,
  priceInfoTotal: PriceInfo,
  promotionIdSeed?: string,
  quantity: number,
  stockStatus: {
    value: number,
    incomingValue: number,
    leadtimeDayCount?: number | null,
    estimatedDelivery?: string | null,
  },
  url: string,
  variantName: string,
  priceListLocked: boolean,
  recommendedQty: number,
}

export type Brand = {
  code: string,
  description: string,
  name: string,
}

export type Category = {
  code: string,
  description: string,
  name: string,
  parentCode: string,
  parentName: string,
}

type CartApiSummary = {
  discount: string;
  discountDisplay: string;
  points: number;
  price: string;
  priceBeforeVat: string;
  priceBeforeVatDisplay: string;
  priceDisplay: string;
  shipping: string;
  shippingBeforeVat: string;
  shippingBeforeVatDisplay: string;
  shippingDisplay: string;
  subtotal: string;
  subtotalBeforeVat: string;
  subtotalBeforeVatDisplay: string;
  subtotalDisplay: string;
  vat: string;
  vatDisplay: string;
  usedPoints: number;
  crossDockingShipping: string;
  crossDockingShippingDisplay: string;
  crossDockingShippingBeforeVat: string;
  crossDockingShippingBeforeVatDisplay: string;
}

export type CartApiResponse = {
  allowPointsPurchase?: boolean;
  appliedPromotions?: AppliedPromotion[],
  comment?: string;
  customerId?: number,
  id: string;
  norceBasketId: string,
  infoTypes?: InfoType[],
  items: CartApiItem[],
  messages?: string[],
  paymentInfo?: { hasSelectedMethod: boolean; isInvoicePayment: boolean; },
  statusId?: number;
  summary: CartApiSummary,
}

export type AppliedPromotion = {
  name: string;
  discountCode: string;
  appliedAmount: number;
  appliedAmountDisplay: '';
  appliedAmountIncVat: number;
  appliedAmountIncVatDisplay: string;
}

export type CheckoutAddressDetails = {
  companyName?: string | null,
  firstName: string | null,
  lastName: string | null,
  orgNo?: string | null,
  street: string | null,
  zip: string | null,
  city: string | null,
  country: string | null,
  phone?: string | null,
  email?: string | null
}

export type CheckoutDeliveryMethod = {
  id:	string,
  name:	string,
  price:	string,
  image:	string,
  selected:	boolean,
}

export type CheckoutPaymentMethod = {
  id:	number,
  name:	string,
  image:	string,
  selected:	boolean,
}

export type CheckoutApiResponse = {
  basket: CartApiResponse,
  paymentMethods: [],
  deliveryMethods: [],
  payments: [],
  shippingAddress: CheckoutAddressDetails
  billingAddress: CheckoutAddressDetails,
  payerAddress: CheckoutAddressDetails,
  buyerCompanyName: string|null,
}

/**
 * If changes, also fix emptyUser below
 */
export type UserProfile = {
  id: number,
  code: number|string;
  email: string,
  ssn: null,
  firstName: string,
  lastName: string,
  phone: string,
  impersonatingCompany: boolean,
  hasSeenOnboarding?: boolean,
  role: {
    id: number,
    name: string,
    type: NorceUserTypes.Admin | NorceUserTypes.User | NorceUserTypes.Finance | NorceUserTypes.Sales | NorceUserTypes.CustomerSuccess | '', // user
  },
  company: Company,
  favoriteProducts: { id: string, quantity: number }[],
  favoriteBrands: { id:string, name:string }[],
  allowPointsPurchase?: boolean,
  currentlyEditingOrderProposal?: OrderProposal,
  savedBasketId?: string,
}

/**
 * This should match the type above
 */
export const emptyUser: UserProfile = {
  id: -1,
  code: '',
  email: '',
  ssn: null,
  firstName: '',
  lastName: '',
  phone: '',
  impersonatingCompany: false,
  role: {
    id: -1,
    name: '',
    type: '', // user
  },
  company: {
    id: -1,
    code: '-1',
    name: '',
    orgNo: '',
    email: '',
    useInvoiceAddressAsDeliveryAddress: true,
    deliveryAddresses: [],
    invoiceAddress: {
      id: -1,
      line1: '',
      line2: '',
      zip: '',
      city: '',
      country: '',
      careOf: '',
    },
    phone: null,
    invoicePaymentMethodAvailable: false,
    customerGroups: [],
  },
  favoriteProducts: [],
  favoriteBrands: [],
};

export type UserProfileAddress = {
  id: number,
  line1: string,
  line2: string,
  zip: string,
  city: string,
  country: string,
  careOf: string,
}

export const emptyUserAddress: UserProfileAddress = {
  id: -1,
  line1: '',
  line2: '',
  zip: '',
  city: '',
  country: '',
  careOf: '',
} as UserProfileAddress;

export type UsersMeCompanyPayload = {
  invoiceAddress?: {
    line1: string,
    line2: string,
    zip: string,
    city: string,
    country: string,
    careOf: string,
  },
  deliveryAddresses?: {
    line1: string,
    line2: string,
    zip: string,
    city: string,
    country: string,
    careOf: string,
  }[],
  email?: string,
  useInvoiceAddressAsDeliveryAddress: boolean,
}

/**
 * @deprecated
 */
export type UserProfileUpdatePayload = {
  id?: number,
  email?: string,
  ssn?: null,
  firstName?: string,
  lastName?: string,
  phone?: string,
  address?: {
    id?: string,
    street?: string,
    zip?: string,
    city?: string,
    country?: string
  },
  password?: string;
  company?: {
    id?: string,
    name?: string,
    orgNo?: string,
    address?: {
      id?: string,
      street?: string,
      zip?: string,
      city?: string,
      country?: string
    },
  }
}

export type OrdersApiResult = {
  itemCount: number,
  items: Order[]
}

export type SalesOrdersApiResult = {
  itemCount: number,
  items: SalesOrder[],
  page: number,
  lastPage: number,
  perPage: number,
}

export type SalesOrder = {
  id: string,
  orderNo: string,
  status: 'CREATED' | 'ALLOCATION' | 'CONFIRMED' | 'BACK ORDER' | 'DELIVERED' | 'INVOICED' | 'CANCELLED' | 'CREDIT CONTROL' | 'PARTLY DELIVERED' | 'ACKNOWLEDGED' | 'ERPCONFIRMED' | 'READYFORPICKUP' | 'PICKEDUP' | 'NOTPICKEDUP' | 'ONHOLD' | 'WAITINGFORCANCEL' | 'WAITINGFORONHOLDREMOVED' | 'CANCELERROR' | 'ACKNOWLEDGEMENTERROR' | 'READYFORRESERVATION' | 'RESERVED' | 'ABORTED',
  companyId: number,
  companyCode: string,
  companyName: string,
  companyCity: string,
  totalSum: string,
  productCount: number,
  orderDate: string,
}

export type InvoicesApiResult = {
  invoices: Invoice[];
}

export type OrderProduct = {
  articleNr: string,
  brand: Brand,
  categories: Category[],
  colorInfo: ColorInfo,
  image: string,
  name: string,
  partNo: string,
  priceInfo: PriceInfo & {
    totalPrice: string,
    totalPriceDisplay: string,
    totalPriceBeforeDiscount: string,
    totalPriceBeforeDiscountDisplay: string,
    totalPriceBeforeVat: string,
    totalPriceBeforeVatDisplay: string,
  }
  quantity: number,
  url: string,
  variantName: string,
  info?: {code: string, value: string}[],

}

export type Order = {
  orderStatus: 'CREATED' | 'ALLOCATION' | 'CONFIRMED' | 'BACK ORDER' | 'DELIVERED' | 'INVOICED' | 'CANCELLED' | 'CREDIT CONTROL' | 'PARTLY DELIVERED' | 'ACKNOWLEDGED' | 'ERPCONFIRMED' | 'READYFORPICKUP' | 'PICKEDUP' | 'NOTPICKEDUP' | 'ONHOLD' | 'WAITINGFORCANCEL' | 'WAITINGFORONHOLDREMOVED' | 'CANCELERROR' | 'ACKNOWLEDGEMENTERROR' | 'READYFORRESERVATION' | 'RESERVED' | 'ABORTED',
  estimatedDeliveryDate: string,
  deliveredDate: string | null,
  orderDate: string,
  orderNo: string,
  id: string,
  companyName: string;
  companyCode: string;
  shippingPrice: string,
  shippingPriceDisplay: string,
  shippingPriceBeforeVat: string,
  shippingPriceBeforeVatDisplay: string,
  crossDockingShipping?: string,
  crossDockingShippingDisplay?: string,
  crossDockingShippingBeforeVat?: string,
  crossDockingShippingBeforeVatDisplay?: string,
  totalOrderDiscount: string,
  totalOrderDiscountDisplay: string,
  totalOrderPrice: string,
  totalOrderPriceBeforeDiscount: string,
  totalOrderPriceBeforeDiscountBeforeVat: string,
  totalOrderPriceBeforeDiscountBeforeVatDisplay: string,
  totalOrderPriceBeforeDiscountDisplay: string,
  totalOrderPriceBeforeVat: string,
  totalOrderPriceBeforeVatDisplay: string,
  totalOrderPriceDisplay: string,
  products: OrderProduct[],
  parcelNumber: string | null, // will be entire link to tracking
  trackingLink?: string,
  orderedBy: string,
  points?: string,
}

export type Invoice = {
  invoiceNo: string;
  invoiceDate: string;
  dueDate: string;
  totalSum: number;
  totalSumDisplay: string;
  voucherType: number;
  status: string;
  isOverdue: boolean;
}

export type OrderProposalPaginatedResult = {
  data: OrderProposal[],
  currentPage: number,
  perPage: number,
  lastPage: number,
  total: number,
  path: string,
}

export type OrderProposal = {
  id: number,
  companyId: number,
  companyName: string,
  salesRepName: string,
  salesRepComment?: string,
  basketId: string,
  basket?: CartApiResponse,
  norceBasketId: number,
  norceCustomerBasketId?: number,
  customerBasketId?: string,
  totalSum?: string,
  status: 'sent' | 'draft' | 'order' | 'declined' | 'expired',
  seen?: string,
  declinedMessage?: string,
  validUntil: string,
  validWeeks?: number,
  createdAt: string,
  updatedAt: string,
}

export type PriceHistoryItem = {
  date: string;
  price: string;
  priceDisplay: string;
  priceBeforeVatDisplay: string;
}
export type PriceHistory = {
  prices: PriceHistoryItem[]
}

export type UserCommunicationPreferences = {
  newsletter: boolean,
  backorderStockAlerts: boolean,
};

export type UserNotification = {
  type: string,
  id: string,
  seen: boolean,
  external_id?: string|number|null,
};

export type PurchaseResponse = {
  basketId: string,
  allowPointsPurchase: boolean,
  delayedUntil?: string|null,
}

export type Company = {
  id: number,
  name: string,
  code: string,
  orgNo: string | null,
  email: string,
  phone: string | null,
  useInvoiceAddressAsDeliveryAddress: boolean,
  deliveryAddresses: UserProfileAddress[],
  invoiceAddress: UserProfileAddress,
  invoicePaymentMethodAvailable: boolean,
  customerGroups: string[],
}

export type CustomerSuccessProduct = {
  name: string;
  partNo: string;
  image: string | null;
}

export type TrackingLink = {
  orderNo: string;
  trackingLink: string;
}

export type ProductBackorderNotice = {
  customerNo: number;
  orderNo: number;
  lineNo: number;
  productNo: string;
  description: string;
  createdDate: number;
  quantity: number;
  estimatedDeliveryDate: string;
}
